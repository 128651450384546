.row {
	display: flex;
	flex-direction: row;
}
.column {
	display: flex;
	flex-direction: column;
}
.space-around {
	justify-content: space-around;
}
.space-between {
	justify-content: space-between;
}
.start {
	justify-content: start;
}
.center {
	justify-content: center;
}
.max-width {
	max-width: 50rem;
}
.max-width-smaller {
	max-width: 45rem;
}
.padding-bottom {
	padding-bottom: 2rem;
}
.padding-top {
	padding-top: 1rem;
}
.form-wrapper > * {
	margin-top: 1rem;
}
